export const countryDeta = {
    au: {countryName:'Australia', currencyCode: "AUD", currencySign: "$"},
    ca: {countryName:'Canada', currencyCode: "CAD", currencySign: "C$"},
    hk: {countryName:'Hong Kon', currencyCode: 'HKD', currencySign: "HK$"},
    in: {countryName:'India', currencyCode:'INR', currencySign: "₹"},
    id: {countryName:'Indonesia', currencyCode:'IDR', currencySign: "Rp"},
    my: {countryName:'Malaysia', currencyCode:'MYR', currencySign: "RM"},
    nz: {countryName:'New Zealand', currencyCode:'NZD', currencySign: "$"},
    ph: {countryName:'Philippines', currencyCode:'PHP', currencySign: "₱"},
    qa: {countryName:'Qatar', currencyCode:'QAR', currencySign: "QAR"},
    sg: {countryName:'Singapore', currencyCode:'SGD', currencySign: "S$"},
    sa: {countryName:'South Africa', currencyCode:'ZAR', currencySign: "R"},
    th: {countryName:'Thailand', currencyCode:'THB', currencySign: "฿"},
    en: {countryName:'USA (EN)', currencyCode:'USD', currencySign: "$"},
    es: {countryName:'USA (ES)', currencyCode:'USD', currencySign: "$"},
    ae: {countryName:'United Arab Emirates', currencyCode:'AED', currencySign: "DH"},
    uk: {countryName:'United Kingdom', currencyCode:'GBP', currencySign: "£"},
    vn: {countryName:'Vietnam', currencyCode:'VND', currencySign: "₫"},
    Gogalaxytravels: {countryName:'United States',currencyCode:'USD', currencySign: "$"}
};


export const countrySign = {
    AUD: '$',
    USD: '$',
    CAD: 'C$',
    HKD: 'HK$',
    INR: '₹',
    IDR: 'Rp',
    MYR: 'RM',
    NZD: '$',
    PHP: '₱',
    QAR: 'QAR',
    SGD: 'S$',
    ZAR: 'R',
    THB: '฿',
    AED: 'DH',
    GBP: '£',
    VND: '₫',
}