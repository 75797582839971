import React from "react";
import { Link } from "react-router-dom";
import "./LegalContent.styles.css";

const PrivacyPolicyPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" cm-legal-content-container">
      <div className="inner-banner"></div>

      <div className="cm-page-center cm-lr-pad cm-section pb-10">
        <div>
          <h2>Privacy Policy of <strong>Gogalaxytravels.com</strong>!</h2>

          <p>At GALAXY TRAVELS AND TOURS LLC d/b/a <strong><a href="https://gogalaxytravels.com/"> GoGalaxyTravels.com</a></strong> (“GoGalaxyTravels”, “we”, “us” or “our”), we are committed to protecting your privacy and safeguarding your personal information.</p>

          <p>Just as our staff regards their privacy as paramount, your privacy is very important to us at GoGalaxyTravels. With that in mind, we have established information handling practices for GoGalaxyTravels that we believe are consistent with the highest standards and best practices of organizations doing business on the Internet, as well as complying with relevant legislation.</p>

          <p>This privacy policy (the “Policy” or “Privacy Policy”) applies to the collection, use and disclosure of Personal information by GoGalaxyTravels, including the practices that apply when using our website www.GoGalaxyTravels.com (“our website” or “our site”), including, specifically, the information we collect about you, when and how we collect that information and what may happen to that information.</p>

          <p>“Personal Information” is any information that is identifiable with you, as an individual. Personal Information, however, does not include certain business contact information.</p>

          <p>GoGalaxyTravels has prepared this detailed Privacy Policy because we believe you should know as much as possible about our practices so that you can make an informed decision about the extent of your use of our facilities.</p>

        </div>
        <div>
          <h3><strong>Simply stated</strong></h3>
          <ul>
            <li>We will always collect your Personal Information by fair and lawful means (for example, when you register with us). We may collect Personal Information from you directly and/or from third parties, where we have obtained your consent to do so or as otherwise required or permitted by law.</li>
            <li>GoGalaxyTravels will not disclose your Personal Information to third parties unless you have authorized us to do so, or required to do so by law;</li>
            <li>GoGalaxyTravels will allow you to view, correct or remove your Personal Information upon written request and in accordance with applicable law; and</li>
            <li>Will take reasonable steps to protect the security of the Personal Information we collect from you.</li>
          </ul>
        </div>
        <div>
          <h3><strong>OUR PRIVACY POLICY</strong></h3>
          <p>We identify the purposes for which we use your Personal Information at the time we collect such information from you and obtain your consent, in any case, prior to such use. We generally use your Personal Information for the following purposes, including as further described in this Privacy Policy (the “Purposes”):</p>
          <ul>
            <li>To provide you with requested services, and to register your engagement with our services.</li>
            <li>To process your orders.</li>
            <li>To manage your account with GoGalaxyTravels.</li>
            <li>To determine your location, including to determine the terms that apply to your orders and your use of our services.</li>
            <li>To respond to questions, comments or concerns regarding GoGalaxyTravels.</li>
            <li>To allow for more meaningful and useful sales and marketing initiatives.</li>
            <li>To conduct analytics to better understand our web traffic and marketing effectiveness.</li>
            <li>To collect opinions and comments in regard to GoGalaxyTravels’s operations.</li>
            <li>To recruit for positions at GoGalaxyTravels and for other employment or marketing-related tasks.</li>
            <li>To investigate legal claims.</li>
            <li>To investigate insurance claims.</li>
            <li>To protect against fraud.</li>
            <li>To administer GoGalaxyTravels websites and any GoGalaxyTravels software applications.</li>
            <li>Such purposes for which GoGalaxyTravels may obtain consent from time to time.</li>
            <li>Such other uses as may be permitted or required by applicable law.</li>
          </ul>

        </div>
        <div>
          <h3><strong>Perusing Our Website Content</strong></h3>
          <p>You can visit our site without revealing who you are or providing any Personal Information.</p>

          <p>When you visit our site we do collect usage information, but it is anonymous and is not linked to you as an individual. We use this data to better understand things like how our site is navigated, how many visitors arrive at specific pages, the length, and frequency of stays at our site, the variety of searches of our site’s database, the types of browsers and computer operating systems used by our visitors and the Internet Protocol addresses from which our visitors connect to our site.</p>

          <p>This information is regularly collected by Internet server software on most sites. GoGalaxyTravels uses this information to improve our site content and ease of use, for instance, providing easier access to popular web pages.</p>
        </div>
        <div>
          <h3><strong>Cookies and Pixel Tagging</strong></h3>
          <p>
            GoGalaxyTravels, or a third-party advertiser we link to, may place a ‘cookie’ or ‘pixel tag’ on your personal the computer when you visit the site or visit a site on which we advertise.
          </p>
          <p>
            These devices are small computer files that our computer sends to your computer and that your computer sends back to us each time you make a request from our site.
          </p>
          <p>
            These devices are used to collect data to help us personalize and improve your experiences on web sites. The data on your use of a web site may be linked to the Personal Information you have chosen to share with us when you register. The information collected with these devices may be used in order to adequately establish the price of our offers.
          </p>
          <p>
            Most Internet browsers automatically accept cookies.
          </p>
          <p>
            However, you can instruct your browser, by editing its options, to stop accepting cookies or prompt you before accepting a cookie from the sites you visit.
          </p>
          <p>
            If you decide not to accept our cookies, your ability to use some features on some web site may be impaired.
          </p>
        </div>
        <div>
          <h3><strong>Third-party Advertisers</strong></h3>
          <p>Internet marketing companies advertise on the Internet, and GoGalaxyTravels, may, at some future date, have third-party advertisers on our site. Third-party advertisers may use cookies and pixel tags to gather information about the ads you have seen and the types of things that you appear interested in.</p>

          <p>GoGalaxyTravels does not share any Personal Information about you with these advertisers unless you give us specific permission to do so, separate from any permission you provide during a registration process.</p>

          <p>These advertisers may use combined information about your visits to our site and other sites in order to provide advertisements about related goods and services that you may be interested in. This Privacy Policy does not cover the policies or practices of these third-party advertisers.</p>
        </div>
        <div>
          <h3><strong>Personal Information When Registering</strong></h3>

          <p>If you decide to register with us, we will collect Personal Information about you that you voluntarily share with us. We collect Personal Information that is necessary for us to provide you with the services offered on our site. As mentioned, this Personal Information includes things like your name, e-mail and mailing address, date of birth, telephone number, payment information, and personal preferences.</p>
        </div>
        <div>
          <h3><strong>Traveler Profile</strong></h3>
          <p>GoGalaxyTravels combines your Personal Information into what we call your ‘Traveller Profile:</p>

          <p>
            We add the data about how you use our Website to your Traveler Profile. We may also collect information about you from outside sources and add it to your Traveler Profile, such as e-tickets or itineraries from airline companies.
          </p>

          <p>
            We collect and use all of the information in your Traveller Profile to improve the quality of our service to you and your experience with us and to otherwise fulfill the Purposes as applicable.
          </p>
          <p>
            By way of example, we may use the combination of information in your Traveller Profile to (a) personalize the service we provide to you, (b) communicate with you about changes to your travel plans, service updates, and new features or outages on our site, and (c) allocate the resources of our systems so that your experience with our site is as efficient as possible.
          </p>
        </div>
        <div>
          <h3><strong>Advertising</strong></h3>
          <p>As part of the registration process, we give you the choice to either receive advertisements, updates, and promotions via e-mail.</p>
          <p>If you choose to receive the above items we hope you will find them interesting and informative. In fact, we will use the information you tell us about your travel preferences and experiences to share customized advertisements, updates and promotions with you that include our recommendations to you about travel specials.</p>
          <p>You should let us know if you do not want us to use your e-mail addresses to deliver to you: (1) our deals updates highlighting flight specials, promotions, and other travel opportunities available on our web site and/or sponsored by our travel service providers and advertisers; and/or (2) our monthly newsletter, when it becomes available, highlighting flight specials, promotions, contests, sweepstakes and other travel opportunities available on our site and/or sponsored by our travel service providers.</p>
          <p>We use different methods, including an e-mail delivery and marketing company, to send the emails that you have agreed to receive.</p>
          <p>Pixel tags and cookies may be used in those e-mail messages and at our website to help us measure the effectiveness of our advertising and to study how visitors use our site.</p>
          <p>Should you no longer wish to receive our promotions, you may use the unsubscribe link included in an email message that we send you.</p>
        </div>
        <div>
          <h3><strong>Online Surveys and Contests</strong></h3>
          <p>We value your comments and opinions. From time to time, we will invite you, as a registered user of our site, to participate in online surveys, but the choice will be yours.</p>

          <p>Each time you purchase, book or reserve travel services or accommodations through our site you may be asked for your feedback on your experience.</p>

          <p>Your participation in these surveys is on an anonymous basis unless we tell you up-front otherwise.</p>

          <p>We may offer you the chance to win a variety of travel-related prizes by sponsoring contests. You choose to participate in these contests or not. If you choose to participate in these contests, you will share certain of your Personal Information with us, like your full name, e-mail address and mailing address.</p>

          <p>Participation in these contests may include participation in an online survey. We will only use your Personal Information to notify you if you win a contest.</p>
        </div>
        <div>
          <h3><strong>Payment Information and Travel History</strong></h3>
          <p>If you register with us, you will be able to book, reserve or purchase travel services or accommodations through our site.</p>
          <p>To do so, you must provide payment information to us – for example, your credit card specifics like card number, expiration date, cardholder name, and billing address.</p>
          <p>We will add this payment information to your Traveller Profile.</p>
          <p>We will keep a history of all your reservations, bookings and purchases of travel services and accommodations through our site in your Traveller Profile unless you choose to edit your Traveller Profile as described below in this Policy (Changing/Removing Personal Information).</p>
        </div>
        <div>
          <h3><strong>Itinerary Changes & Travel Alerts</strong></h3>
          <p>If you book, reserve or purchase travel services through our site, you will have the option to receive and/or have others receive last minute updates about your travel arrangements, such as changes in gate assignment or delays in departure or arrival time.</p>
          <p>These ‘Travel Itinerary & Travel Alerts’ can be received by you or others of your choosing via email or text messages.</p>
          <p>If you decide to direct a Travel Itinerary & Travel Alert to someone other than yourself, we will collect Personal Information about that other person that is necessary to send the requested Travel Itinerary & Travel Alert (i.e., that person’s name and phone number or e-mail address).</p>
          <p>You represent and warrant that any such person to whom you direct a Travel Itinerary & Travel Alert is a person with whom you have a personal or family relationship.</p>
          <p>You will need to make sure that each of the other recipients of a Travel Itinerary & Travel Alert agrees, in advance, that you can disclose their Personal Information to us for this purpose, and you represent and warrant that you have such agreement prior to any such disclosure.</p>
        </div>
        <div>
          <h3><strong>Sharing of Personal Information</strong></h3>
          <p>Generally, we only disclose your Personal Information to those travel providers that you decide to make arrangements with through our site.</p>
          <p>For example, when you purchase an airline ticket or reserve a rental car or hotel room, we will provide the airline, car rental agency or hotel, as the case may be, with the Personal Information needed to process your transaction.</p>
          <p>We use our reasonable efforts to ensure that these travel providers will also protect your Personal Information.</p>
          <p>We contract with service providers to perform various functions necessary to operate our business – for example, to deliver tickets, ship items, and process credit cards. We will share with these other companies only that Personal Information they require to fulfill your orders. These companies agree that they will not use or disclose Personal Information for any other purpose.</p>
          <p>Other than as explained in this Policy, we will not disclose any Personal Information we collect from you to others without your permission, except in certain limited circumstances, including (a) to protect the security of our Website and our rights under our Terms and Conditions of Use; (b) to protect ourselves against liability; (c) in response to legal process, (d) if we believe in good faith that the law requires it; or (e) as required by governmental security regulations. In the event of a change of ownership, sale, merger, liquidation, reorganization or acquisition of the corporation, in whole or in part, Personal Information may be transferred as part of the negotiation process or the transaction, as long as, the party receiving such Personal Information agrees to be bound by the terms of this Privacy Policy. It should be remembered that should you choose to request certain services such as religious or special diet meals certain religious or health information may be deduced by the travel provider and your permission is automatically granted to forward such information when you make such special requests. Travelers information shared with travel providers may be communicated to countries security agencies under local legal requirements. For example, any travelers to/from the U.S. may have their data captured by the U.S. Homeland Security Agency, which can store it for up to 50 years and may share such information with other U.S. Government Agencies.</p>
        </div>
        <div>
          <h3><strong>Corporate Travel</strong></h3>
          <p>If you, or someone acting on your behalf, purchase(s) travel services or accommodations through our site and (a) you, or someone acting on your behalf, tell(s) us that your purchase is for corporate travel purposes either by indicating in the booking path that the purchase is for corporate travel or indicating that the credit card being used is a corporate credit card and (b) we have entered into an agreement with your corporation to provide data on the purchase of travel services or accommodations by the corporation’s personnel, we will provide your corporation with the Personal Information necessary for the corporation to track your corporate travel purchased on our site.</p>
        </div>
        <div>
          <h3><strong>Other Travellers</strong></h3>
          <p>If you decide to book, reserve or purchase travel services or accommodations for other Travellers using your Traveller Profile, we will collect certain Personal Information about these other Travelers.</p>
          <p>You will need to make sure that each of these other travelers agrees, in advance, that you can disclose their Personal Information to us. We will retain these other travelers’ Personal Information in your Traveller Profile.</p>
        </div>
        <div>
          <h3><strong>Changing/Removing Personal Information</strong></h3>
          <p>We are committed to giving you control over your Personal Information. If you are a registered user you can change your password and certain of the Personal Information in your Traveler Profile through your account. Otherwise, you can do so, by contacting us at contact@GoGalaxyTravels.com.</p>
          <p>If you make a written request to review any Personal Information about you that we have collected, utilized or disclosed, we will provide you with any such Personal Information to the extent required by law. We will make such Personal Information available to you in a form that is generally understandable. We may request that you provide sufficient identification to permit access to the existence, use or disclosure of your Personal Information. Any such identifying information shall be used only for this purpose. We will not charge any costs for you to access your Personal Information in our records without first providing you with an estimate of the approximate costs if any.</p>
          <p>We will ensure that your Personal Information is kept as accurate, complete and up-to-date as possible. We will not routinely update your Personal Information unless such a process is necessary. We expect you, from time to time, to supply us with written updates to your Personal Information, when required.</p>
          <p>At any time, you can challenge the accuracy or completeness of your Personal Information in our records. If you successfully demonstrate that your Personal Information in our records is inaccurate or incomplete, we will amend the Personal Information as required. Where appropriate, we will transmit the amended information to third parties having access to your Personal Information.</p>
        </div>
        <div>
          <h3><strong>Notification of Changes</strong></h3>
          <p>This Privacy Policy was last updated on February 27th, 2019.</p>
          <p>We may change this Privacy Policy from time to time. It is current as of the date above.</p>
        </div>
        <div>
          <h3><strong>Combined Non-Personal Information</strong></h3>
          <p>We may also combine anonymous information supplied by you and other users during registration on our site with information about your use and others’ use of our site.</p>
          <p>We will use this combined information to describe our user base, without reference to any specific individual, to potential partners, investors, and advertisers.</p>
          <p>We will not share your Personal Information with any other users of our site, but we may use the information we collect about your travel preferences and experiences to make recommendations to other users of our site.</p>
          <p>By way of example, if we know that you and other users of our site who have traveled to a particular destination had positive experiences at a hotel in that destination, we might recommend that hotel to others who will be traveling to that destination.</p>
          <p>We may also share aggregated statistical information about users of our site and our service and product offerings with others, including our vendors and partners.</p>
          <p>We will not link this statistical information to any Personal Information that can identify you as an individual.</p>
        </div>
        <div>
          <h3><strong>How We Protect Your Information</strong></h3>
          <p>We are committed to safeguarding your information. For that reason, we employ security technology professionals and utilize industry standard security technology appropriate to the sensitivity of the applicable Personal Information. Only those employees who need access to Personal Information to fulfill their employment duties will have access to it.</p>
        </div>
        <div>
          <h3><strong>Children and Minors</strong></h3>
          <p>You must be at least the age of majority in the jurisdiction where you reside in order to register for or use the services available on our site.</p>
          <p>GoGalaxyTravels staff are parents and GoGalaxyTravels believes that parents should play a central role in their child’s online activities and should monitor those activities to ensure that sites visited by their child, or minor, are respecting their child’s privacy.</p>
        </div>
        <div>
          <h3><strong>Storage of Personal Information</strong></h3>
          <p>Your information will be stored on servers located in Chicago, Illinois, United States of America. Therefore, local tribunals and legislation will have jurisdiction with regards to your Personal Information.</p>
          <p>We may keep a record of your Personal Information, correspondence or comments, in a file specific to you. We will utilize, disclose or retain your Personal Information for as long as necessary to fulfill the purposes for which that Personal Information was collected and as permitted or required by law.</p>
        </div>
        <div>
          <h3><strong>Contact Us</strong></h3>
          <p>If you have any questions or concerns regarding this Policy or our information handling practices or to make a complaint, you may contact us via e-mail at contact@GoGalaxyTravels.com. We will attempt to respond to your written questions or concerns within thirty (30) days after we receive them. We will advise you in writing if we cannot meet your requests within this time limit. You have the right to make a complaint to the Privacy Commissioner of Canada or applicable provincial privacy commissioner in respect of this time limit.</p>
          <ul>
            <li>GALAXY TRAVELS AND TOURS LLC</li>
            <li>767 W Happfield Dr Arlington Heights, IL 60004, USA</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
