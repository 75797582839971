import React from "react";
import { Link } from "react-router-dom";
import "./LegalContent.styles.css";

const TncPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="cm-legal-content-container">
      <div className="inner-banner"></div>
      <div className="cm-section cm-page-center cm-lr-pad pb-10">
        <div>
          <h2>Terms & Conditions</h2>
          <p>Welcome to the GoGalaxyTravels.com website. The GoGalaxyTravels.com website, [mobile or tablet applications], or any other feature on the GoGalaxyTravels.com platform (collectively our “Website”) is designed to be as user- friendly, informative and secure as possible. Our Website is provided solely to assist customers in gathering travel information – determining the availability of travel-related goods and services, making legitimate reservations or otherwise transacting business with travel suppliers. Our website is intended for personal, non-commercial use. The terms “we”, “us”, “our”, and “GoGalaxyTravels.com” refer to “Galaxy Travels And Tours LLC” and “You” or “your” refers to you as the user of our Website.</p>
          <p>Our Website is offered to you conditioned upon your acceptance without modification of all the terms, conditions, and notices set forth below (collectively, the “Terms” or “Agreement”). This The agreement describes the terms and conditions applicable to the services available through our Website and details your responsibilities, thereby limiting the liability of GoGalaxyTravels.com and its third-party suppliers or providers. These include, without limitation, the airlines, hotels, rental car companies and other suppliers that provide travel or other services through our Website (such third parties are collectively referred to as the “Travel Service Providers”). Please read these Terms & Conditions carefully, as they contain important information. You should also read our Privacy Policy, which also governs your use of our Website, and is incorporated by reference in this Agreement. By accessing or using our Website, booking any reservations for travel products or services on our Website, or contacting our call centre agents, you agree that the Terms then in force shall apply. If you do not agree to the Terms, please do not use or book any reservations through our Website or our call centre agents. GoGalaxyTravels.com reserves the right, in its sole discretion, to amend, modify, or alter this Agreement at any time by posting the amended Terms on this site. We recommend that you review these periodically. The amended Terms shall be effective from and after the date that they are posted on our Website.</p>
          <p>
            Our Website is intended to provide services primarily for residents of North America.
          </p>
          <p>BY ACCEPTING THESE TERMS, YOU ARE AGREEING TO THE MANDATORY ARBITRATION AND CLASS ACTION WAIVER CONTAINED BELOW.</p>
        </div>
        <div>
          <h3>USE OF OUR WEBSITE</h3>
          <p>As a condition of your use of our Website, call centre agents, and other products and service provided by GoGalaxyTravels.com, including any content (together “Services”), you warrant that:</p>
        </div>
        <ul>
          <li>You are at least 18 years of age;</li>
          <li>You possess the legal authority to engage in a legally binding contractual relationship;</li>
          <li>You will use our Website in accordance with this Agreement;</li>
          <li>
            You will only use the Services to make legitimate reservations for you or for another person for whom you are legally authorized to act;
          </li>
          <li>You will inform such other persons about the Terms & Conditions that apply to the reservations you have made on their behalf, including all rules and restrictions applicable thereto;</li>
          <li>All information provided by you to the Services is true, accurate, current and complete;</li>
          <li>If you have a GoGalaxyTravels.com account, you will safeguard your account information and will supervise and be completely responsible for any use of your account by you and anyone other than you.</li>
          <li>GoGalaxyTravels.com retains the right, at our sole discretion, to deny access to anyone to the Services we offer, at any time and for any reason, including, but not limited to, for violations of this Agreement.</li>
        </ul>
        <div>
          <h3>RESOLUTION OF DISPUTES:</h3>
          <p>You agree to give us an opportunity to resolve any disputes or claims relating in any way to our Website, any dealings with our customer service agents, any services or products provided, any representations made by us, or our Privacy Policy as per section 25 of the present Terms and Conditions (“Claims”) by contacting GoGalaxyTravels.com Customer Support at 1-847-999-6771 or sending an email to: contact@GoGalaxyTravels.com. If we are not able to resolve your Claims within 60 days, you may seek relief through arbitration or in small claims court, as set forth below.</p>
        </div>
        <div>
          <h3>GOVERNING LAW; SUBMISSION TO JURISDICTION:</h3>
          <p>These Terms & Conditions and the rights of the parties hereunder shall be governed by and construed in accordance with the laws of the State of New York, USA. Unless otherwise mutually agreed by the parties in writing or as described in the Mandatory Arbitration provision below, you consent to the exclusive jurisdiction of the courts located in New York in any action arising out of or in connection with this Terms and Conditions. Unless otherwise prohibited by applicable law, any Claim must be brought within two (2) years from the date on which such claim arose or accrued.</p>
        </div>
        <div>
          <h3>MANDATORY ARBITRATION:</h3>
          <p>Any and all Claims will be resolved via binding arbitration initiated through the American Arbitration Association (“AAA”); except that you may assert Claims on an individual basis in a small claims court located in [New York County, New York] if they qualify under the rules of the small claims court. This includes any Claims you assert against us, our subsidiaries or affiliates, Travel Service Providers or any companies offering products or services through us (which are beneficiaries of this arbitration agreement). This also includes any Claims that arose before you accepted these Terms, regardless of whether prior versions of the Terms required arbitration. Please note that GoGalaxyTravels.com may also undertake any Claims against you at the small claims court.</p>
          <p>There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including statutory damages, attorneys’ fees, and costs), and must follow and enforce these Terms as a court would.</p>
          <p>Arbitrations will be conducted by the American Arbitration Association (AAA) under its rules, including the AAA Consumer Rules. Payment of all filing, administration and arbitrator fees will be governed by the AAA’s rules, except as provided in this section. If your total Claims are less than $10,000, we will reimburse you the filing fees that you paid to the AAA and we will pay your arbitrator’s fees. You may choose to have an arbitration conducted by telephone, based on written submissions, or in person in the state where you live or at another mutually agreed location.</p>
          <p>To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your Claims to “GoGalaxyTravels.com Legal: Arbitration Claim Manager,” at contact@GoGalaxyTravels.com. If we request arbitration against you, we will give you notice at the email address or street address you have provided. The AAA’s rules and filing instructions are available at www.adr.org or by calling 1-847-999-6771.</p>
          <p>Any and all proceedings to resolve Claims will be conducted only on an individual basis and not in a class, consolidated or representative action. If for any reason a Claim proceeds in court rather than in arbitration, we each waive any right to a jury trial. The Federal Arbitration Act and federal arbitration law apply to this agreement. An arbitration decision may be confirmed by any court with competent jurisdiction.</p>
          <p>YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS & CONDITIONS, WE ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION WITH RESPECT TO THE CLAIMS COVERED BY THIS MANDATORY ARBITRATION PROVISION.</p>
          <p>All Claims you bring against GoGalaxyTravels.com must be resolved in accordance with this “Resolution of Disputes” section. All Claims filed or brought not in accordance with this “Resolution of Disputes” section shall be considered improperly filed and void. Should you file a Claim not in accordance with this “Resolution of Disputes” section, we may recover from you our attorneys’ fees and costs up to US$1,000, provided that we have notified you in writing of the improperly filed Claim, and you have failed to promptly withdraw the Claim.</p>
        </div>
        <div>
          <h3>TRAVEL INFORMATION – GENERAL:</h3>
          <p>Our Website provides extensive information related to travel to our customers. It contains information about vacation destinations, tour packages, and travel providers as well as airfares, flight schedules, and cruise details. It also contains information for travellers about insurance and foreign currencies.</p>
          <p>
            We receive this information from third-party sources such as airlines, hotels, tour operators and transportation providers. We always take reasonable care to make sure this information is accurate and up-to-date. However, we cannot guarantee the accuracy of this information or that it is the most current information available and cannot be held liable for any inaccuracies. Moreover, please understand that all the information on our website is subject to change without prior notice. Also, travel products, packages, and services described on our website are subject to availability.
          </p>
          <p>As a traveller, you must know and understand the applicable legal requirements related to travel, including passport, visa, and health requirements. We will assist you in this regard, both through our website and with live support. However, the ultimate responsibility for obtaining this information and complying with any and all passport, visa, health, or other requirements remains solely and exclusively with you.</p>
          <p>Additional terms and conditions will apply to your reservation and purchase of travel-related goods and services that you select. Please read these additional terms and conditions carefully. In particular, if you have purchased an airfare, please ensure you read the full terms and conditions of carriage issued by the supplier, which can be found on the supplier’s website. You agree to abide by the terms and conditions of purchase imposed by any supplier with whom you elect to deal, including, but not limited to, payment of all amounts when due and compliance with the supplier’s rules and restrictions regarding availability and use of fares, products, or services. Airfare is only guaranteed once the purchase has been completed and the tickets have been issued. Airlines and other travel suppliers may change their prices without notice. We reserve the right to cancel your booking if full payment is not received in a timely fashion.</p>
          <p>If your itinerary includes flights operated by more than one airline, please read carefully each such airline’s terms and conditions, which can be found on each airline’s website. Each airline will have its own restrictions, rules, and fees. If one of these flights is affected by an airline change (e.g. cancellation or rescheduling) that causes a customer to make changes to the other flight, the customer may be responsible for any fees or ticket cost incurred for making changes to the unaffected flight. Such airlines may charge their own fees for changes, refunds, or if exchanges are requested. You are responsible for complying with each airline’s terms and conditions, which may differ (for example, check-in times and limits on baggage size/weight). It is advisable you print your outbound and return portions of your e-ticket confirmation for all flights prior to traveling. You may be asked for proof of your return ticket at check-in. GoGalaxyTravels.com may offer customers the opportunity to book a reservation for a combination of two one—way tickets instead of a roundtrip ticket.</p>
          <p>Combined one-way tickets may provide a greater choice of flights, are often cheaper and can be combined on the same airline or on different airlines. Unlike roundtrip tickets, each one-way ticket is subject to its own rules, restrictions, and fees. If one of these flights is affected by an airline change (e.g. cancellation or rescheduling) that causes a customer to make changes to the other flight, the customer will be responsible for any fees incurred for making changes to the unaffected flight.</p>
          <p>For certain low-cost carriers, the price of your flight has been converted from a different currency for convenience purposes to provide you an estimate of the amount of the purchase in your local currency. As a result, when you book, the amount charged to your credit card by the airline may be slightly different due to currency fluctuations. Your statement may also include an application fee from your card issuer to process the transaction plus any applicable taxes for international purchases.</p>
          <p>Federal law forbids the carriage of hazardous materials aboard aircraft in your luggage or on your person. A violation can result in five years’ imprisonment and penalties of $250,000 or more (49 U.S.C. 5124). Hazardous materials include explosives, compressed gases, flammable liquids and solids, oxidizers, poisons, corrosives, and radioactive materials. Examples: Paints, lighter fluid, fireworks, tear gases, oxygen bottles, and radio-pharmaceuticals. There are special exceptions for small quantities (up to 70 ounces total) of medicinal and toilet articles carried in your luggage and certain smoking materials carried on your person.</p>
        </div>
        <div>
          <h3>INTERNATIONAL TRAVEL:</h3>
          <p>If you are traveling internationally, please be advised that the provisions of the Montreal Convention and the Warsaw Convention may be applicable to your journey including portions entirely within the countries of departure or destination. The Conventions govern and in most cases limits the liability of carriers to passengers for death or personal injury.</p>
          <p>Passengers are responsible for ensuring that they meet foreign entry requirements and that their travel documents, such as but not limited to passports and visas (transit, business, tourist, and otherwise), are in order and any other foreign entry requirements are met. We urge customers to review travel prohibitions, warnings, announcements, and advisories issued by the relevant governments prior to booking travel to international destinations.</p>
          <p><strong>Visa and Passport – </strong>You must consult the relevant Embassy or Consulate for this information. Requirements may change and you should check for up-to-date information before booking and departure. We accept no liability if you are refused entry onto a flight or into any country due to your failure to carry the correct and adequate passport, visa, or other travel documents required by any airline, authority, or country, including countries you may just be transiting through. This includes all stops made by the aircraft, even if you do not leave the aircraft or airport.</p>
          <p><strong>Health – </strong>HRecommended inoculations for travel may change and you should consult your doctor for current recommendations before you depart. It is your responsibility to ensure that you meet all health entry requirements, obtain the recommended inoculations, take all recommended medication, and follow all medical advice in relation to your trip.</p>
          <p><strong>Disinsection –</strong> Although not common, most countries reserve the right to disinsect aircraft if there is a perceived threat to public health, agriculture or environment. The World Health Organization and the International Civil Aviation Organization have approved the following disinsection procedures: (1) spray the aircraft cabin with an aerosolized insecticide while passengers are on board or (2) treat the aircraft’s interior surfaces with a residual insecticide while passengers are not on board.</p>
          <p>BY OFFERING RESERVATIONS FOR TRAVEL PRODUCTS IN PARTICULAR INTERNATIONAL DESTINATIONS, GALAXY TRAVELS AND TOURS LLC DOES NOT REPRESENT OR WARRANT THAT TRAVEL TO SUCH AREAS IS ADVISABLE OR WITHOUT RISK, AND IS NOT LIABLE FOR DAMAGES OR LOSSES THAT MAY RESULT FROM TRAVEL TO SUCH DESTINATIONS.</p>
        </div>
        <div>
          <h3><strong>AIRLINE BAGGAGE POLICIES:</strong></h3>
          <p>Each airline has its own policies regarding baggage allowances, fees, and restrictions. These policies differ from airline to airline and can change at any time. We try our best to display current baggage fee information on our Website, but we cannot guarantee the accuracy of this information. Ultimately, you are responsible for verifying your airline’s baggage policies and fees before your departure. Also, please be aware that baggage fees are not included in the cost of your trip.</p>
        </div>
        <div>
          <h3><strong>CHECK-IN:</strong></h3>
          <p>Every airline will have their requirements pertaining to check-in. It is important to verify what the check-in requirements are prior to your flight.</p>
          <p>As per the flight industry standard, we recommend the following for your check-in:</p>
          <ul>
            <li>Domestic flights: Minimum 90 minutes prior to departure</li>
            <li>Transborder and International flights: Minimum 3 hours prior to departure</li>
          </ul>
        </div>
        <div>
          <h3><strong>AIRLINE SCHEDULE CHANGE</strong></h3>
          <p>Changes to flight schedules, including flight cancellations, can occur for any number of reasons, including bad weather, mechanical problems, crew issues, and civil unrest. Schedule changes can also include the change to your original flight from a direct non-stop itinerary to include a stop(s) and/or connection(s) to your final destination. Please note that all schedule changes stem from the airlines directly.</p>
          <p>When schedule changes including cancellations occur, we do our best to notify our customers of any changes to their itinerary, by phone and/or email and assist with providing available options based on airline guidelines. However, sometimes the airline does not provide advance notice of the change or cancellation. For this reason, it is your responsibility to reconfirm your flight directly with us or with the airline 72 hours prior to your scheduled departure, as well as 24 hours prior to your scheduled flight date to safeguard against any potentially missed flights.</p>
          <p>The airlines govern the available recourses to you and are responsible for providing you with alternative flight options taking you to your final destination. In addition, once you have checked in for your flight (including online), the airline takes control of your reservation, therefore, any cancellations, delays and/or schedule changes remain the responsibility of the airline to rebook your flight to your final destination.</p>
          <p>However, if you don’t find out about the schedule changes, including cancellations until 24 hours prior to departure or if you’re already at the airport, or you are in-between flights, we strongly recommend you work directly with the airline staff to understand your options for alternative transportation, as they are better placed to facilitate an efficient and expeditious solution. Please note that in some cases, especially during bad weather, your options may be limited.</p>
        </div>
        <div>
          <h3><strong>FUTURE TRAVEL CREDITS</strong></h3>
          <p>Future travel credits are subject to the airlines fare rules and your ticket restrictions.</p>
          <p>Future travel credits are non-refundable and non-transferable to another traveler. When the credit is redeemed, it must be used by the same person whose name appears on the original ticket.</p>
          <p>Future travel credits are only applicable to the airline originally used to book your initial ticket.</p>
          <p>If a future travel credit is applicable, depending on the airline fare rules and your ticket restrictions, it may be used towards the value of a new ticket which must be of equal or higher value. It must be reissued and used within the time period permitted by the original rules of the ticket.</p>
          <p>In addition, additional fees, the difference in fare and routing restrictions may apply.</p>
          <p><strong>For additional fees, please refer to section 13 of the following terms and conditions.</strong></p>
        </div>
        <div>
          <h3><strong>OVERBOOKING OF FLIGHTS</strong></h3>
          <p>Airline flights may be overbooked, and there is a slight chance that a seat will not be available on a flight for which a person has a confirmed reservation. Each airline has its own rules for dealing with such scenarios, which are contained in the airline’s contract of carriage. Check with the airline or call us for details.</p>
        </div>
        <div>
          <h3><strong>PRICES</strong></h3>
          <p>All prices displayed on our Website are subject to change at any time without prior notice. Airfare is only guaranteed once the purchase has been completed and the tickets have been issued. Airlines and other Travel Service Providers may change their prices without notice.</p>
          <p>If a price increase occurs after you have made a reservation that affects your travel package, we will notify you of the price increase before taking any further steps. However, no price increases will affect your travel package once your reservation has been finalized.</p>
          <p>Pricing is displayed in USD and/or CAD currency depending on your booking.</p>
          <p>The GoGalaxyTravels.com FareLock feature cannot be removed or credited once it has been submitted.</p>
          <p>The rate applied on the date of issuance of the ticket is only valid for a ticket fully utilized and in the sequential order of flight segments on the dates indicated. Improper use may void the ticket and result in cancellation of the entire trip.</p>
        </div>
        <div>
          <h3><strong>CANCELLATIONS AND REFUNDS</strong></h3>
          <p>All reservations are non-refundable unless otherwise stated. If you find that you must cancel a reservation for any reason, please contact us. We will do all we can to assist you in this process. However, please be aware that even if your cancellation is allowed and your reservation is thus refundable, it may be subject to an administrative cancellation fee displayed in the table below plus additional fees charged by the Airlines and other Travel Service Provider.</p>
          <p>The following fees will be charged in the currency of the related booking:</p>
          <table border="1" style={{ borderCollapse: "collapse", padding: "5px", textAlign: "center" }}>
            <tr>
              <th>Cancellation Type</th>
              <th>Domestic</th>
              <th>International</th>
              <th>Business</th>
            </tr>
            <tr>
              <td>Void</td>
              <td>$75</td>
              <td>$75</td>
              <td>$75</td>
            </tr>
            <tr>
              <td>Refund</td>
              <td>$100</td>
              <td>$250</td>
              <td>$250</td>
            </tr>
            <tr>
              <td>Future Travel Credit</td>
              <td>$75</td>
              <td>$150</td>
              <td>$150</td>
            </tr>
          </table>
          <p>For U.S. bookings, if a ticket is canceled within 24 hours of purchase, an administrative cancellation the void fee will be charged. For Canadian bookings, if a ticket is canceled before 11:59 pm EST on the same day of purchase, an administrative cancellation void fee will be charged.</p>
          <p>For U.S. bookings, even if your ticket is <b>non-refundable:</b></p>
          <ul>
            <li>You may cancel your booking and receive the full amount returned back to your credit card, subject to our cancellation fees, within 24 hours of purchase.</li>
            <li>You may choose to opt-in to our Extended Cancellation Policy, for $19.99 per passenger, which will allow you to cancel up to a full 24 hours after purchase without subjecting you to our standard administrative cancellation fees.</li>
            <li>The GoGalaxyTravels.com Extended Cancellation Policy feature cannot be removed or credited once your reservation has been submitted.</li>
          </ul>
          <p>For CAN bookings, even if your ticket is <b>non-refundable:</b></p>
          <ul>
            <li>You may cancel your booking and receive the full amount returned back to your credit card the same day, subject to our cancellation fees.</li>
            <li>You may choose to opt-in to our Extended Cancellation Policy, for $19.99 per passenger, which will allow you to cancel the same day of purchase without subjecting you to our standard administrative cancellation fees.</li>
            <li>The GoGalaxyTravels.com Extended Cancellation Policy feature cannot be removed or credited once your reservation has been submitted.</li>
          </ul>
          <p>Refund requests are subject to the airline rules and regulations of purchased tickets. If the refund request is approved by the airline, we are unable to provide a specific timeline for how long it may take for this requested refund to be processed. All refund requests are processed in a sequential format. Once you have provided us with your cancellation request, we will then send you an email notification that your request has been processed.</p>
          <p>Our service fees associated with the original travel reservation or booking are not refundable. Please note that we are dependent on the Travel Service Providers for receiving the requested refunds. Once the refund has been approved by the Travel Service Provider it may take additional time for this to appear on your credit card statement. Generally, all Travel Service Providers will charge a penalty for a refund. This entire process may take 60-90 days from receipt of your request for receiving credit on your statement.</p>
        </div>
        <div>
          <h3><strong>EXCHANGE AND CHANGE FEES</strong></h3>
          <p>All reservations are non-refundable and non-transferable unless otherwise stated. If you need to make a change to your reservation and that change is allowed, please be advised that such change is subject to the fees in the table below:</p>
          <p>In addition, there may also be supplementary fees and/ or difference of fare by the Travel Service Provider (airlines, hotels, cruise lines, etc.) which will be added to the total cost of your reservation.</p>
          <p>Please be aware that once you have made a reservation, name changes are not permitted. If you find you need to change or correct the spelling of a name after you’ve made a reservation, you will have to cancel your original reservation if permitted and then make a new reservation with a new flight at the then-current rate using the correct spelling of the name. This will likely incur fees and penalties. Therefore, it is imperative and your responsibility to verify the spelling of the names of all passengers before making your reservation.</p>
        </div>
        <div>
          <h3><strong>PAYMENT AND FLIGHT INFORMATION AND CONFIRMATION</strong></h3>
          <p>Some banks and credit card companies charge a fee for international transactions. They will appear on your credit or bank card statement as a foreign or international transaction fee. For example, if you make a travel reservation through our website from outside the United States using a U.S. credit card, your bank may convert the payment amount to your local currency and may charge you a fee for the conversion. The amount of the charge appearing on your credit or bank card statement may be in your local currency and different than the purchase amount shown on the billing summary page for the reservation.</p>
          <p>In addition, a foreign transaction fee may be assessed if the bank that issued your credit card is located outside the United States.</p>
          <p>Booking international travel through our website may be considered an international transaction by the bank or credit card company since GoGalaxyTravels.com may pass your payment on to an international travel supplier.</p>
          <p>Your bank or credit card company determines the currency exchange rate and the amount of the foreign transaction fee on the day it processes the transaction. Please contact your bank or credit card company should you have any questions about these fees or the exchange rate applied to your transaction.</p>
          <p><b>Booking notification: </b>Once your purchase is complete, you should receive an email titled “Booking Notification.” Your booking may provide you with a confirmation number before a ticket has been issued. If this is the case, the booking process is not complete and the fare is subject to change until a ticket is issued.</p>
          <p>Once your ticket has been issued, you should receive your electronic ticket.</p>
          <p>We strongly recommend that you re-confirm your flight reservation with the airline 24 hours prior to departure for domestic flights, and 72 hours prior to departure for international flights.</p>
        </div>
        <div>
          <h3><strong>SPECIAL REQUESTS: SEATS, MEALS AND FREQUENT FLYER</strong></h3>
          <p>Please note that requesting specific seats, meals, frequent flyers etc. are requests only. The airline reserves the right to make revisions to the seat allocation without notification. All requests should be confirmed with the airline and we cannot guarantee that passengers will be assigned the seats they’ve requested. Furthermore, we are unable to promise that your meal/frequent flyer/other special requests will be confirmed by the airline in question. It is your responsibility to contact the airline you’ve booked with to confirm the requests you’ve made.</p>
        </div>
        <div>
          <h3><strong>SUITABILITY OF TRAVEL PRODUCTS AND SERVICES</strong></h3>
          <p>On our website, we offer a variety of travel products and services to our customers. However, we do not represent or warrant that any of these travel products and services are or will be suitable and proper for you.</p>
          <p>You agree to release us from any claims relative to the travel products and services detailed on our website, including but not limited to claims that these travel products and services are not or were not suitable for you.</p>
        </div>
        <div>
          <h3><strong>SPECIALS</strong></h3>
          <p>From time to time we offer “specials” on our website. This section applies to all the specials we offer on our Website. As well, all the terms and conditions spelled out above apply to specials we offer on our Website.</p>
          <p>Specials are only available for a limited time. Please contact us if need be to determine whether a special shown on our website is still available.</p>
          <p>Specific terms and conditions may apply to any special shown on our website. Please contact either us or the third-party provider of the special to determine what terms and conditions apply to that special, if any, and how they may affect you.</p>
          <p>Payment for any specials that you book is due within 72 hours of our confirmation unless otherwise stated by us in writing. If you fail to pay for the specials within this 72 hours timeframe, your booking may be canceled. We accept no responsibility for any loss you incur as a result of cancellation for non-payment within 72 hours.</p>
        </div>
        <div>
          <h3><strong>UNACCOMPANIED MINORS</strong></h3>
          <p>Tickets will not be sold directly to unaccompanied minors age 16 and under. Each airline sets its own policies and regulations for children traveling without adult supervision. Some airlines may not allow unaccompanied minors to travel without an adult. Some airlines will only allow unaccompanied minors to travel on non-stop flights. Many airlines may require additional fees to be paid at check-in. You must call the airline to verify rules and restrictions for an unaccompanied minor traveling alone.</p>
        </div>
        <div>
          <h3><strong>CREDIT CARD CHARGEBACKS</strong></h3>
          <p>You have the ability to dispute charges with credit card companies (“chargebacks”). If you have a question about a charge on your credit card statement, we encourage you to call GoGalaxyTravels.com prior to disputing a charge with your credit card company so we may discuss and answer any questions or concerns you may have about our charges. In all cases, GoGalaxyTravels.com will work with you in resolving your concerns. GoGalaxyTravels.com retains the right to dispute any chargeback that it believes is improper, as described more fully below. GoGalaxyTravels.com also retains the right to fully cancel any travel reservation in the event of a chargeback related to that reservation.</p>
          <p>By using our service to make a reservation with a Travel Service Provider, you accept and agree to the relevant cancellation policy of that Travel Service Provider. In all cases, the cancellation policy of each reservation is made available on our website. Please note that certain rates or special offers are not eligible for cancellation or change. GoGalaxyTravels.com deems the following chargeback scenarios as improper and retains the right to investigate and rebut any such chargeback claims and to recover costs of such chargeback claims from you.</p>
          <ul>
            <li>Chargebacks resulting from non-cancellable reservations in the event that GoGalaxyTravels.com or the Travel Service Provider cannot provide a refund, whether or not the reservation is used.</li>
            <li>Chargebacks resulting from charges authorized by family, friends, associates or other third parties with direct access to You, the User’s, credit card.</li>
            <li>Chargebacks arising from the Travel Service Provider’s failure to deliver a product or service in a manner that’s consistent with the Travel Service Provider’s product description.</li>
            <li>Chargebacks resulting from force majeure or other circumstances that are beyond the control of <a href="https://gogalaxytravels.com/">GoGalaxyTravels.com</a></li>
          </ul>
        </div>
        <div>
          <h3><strong>INTELLECTUAL PROPERTY</strong></h3>
          <p>Our Website, including its underlying software and its text, design, graphics, layout, and content, is owned or licensed by us or by the respective owners. All material on our Website is protected by Canadian and international intellectual property laws.</p>
          <p>As a visitor to or user of our Website, you have permission to view, use and electronically copy the pages and content of our Website through the usual and ordinary use of a web browser.</p>
          <p>Any other use of our Website and its contents, such as copying, distributing, selling, modifying, transmitting, reusing, re-posting or publishing, is not permitted and is strictly prohibited without the specific written permission of the owner(s) of such material.</p>
          <p>Any unauthorized use of our website or its contents will breach this agreement and may void your permission to use our Website. It may also violate copyright and other laws.</p>
          <p>Certain trademarks, service marks, business names, company names, logos, trade names and presentation techniques (trade dress) used on our Website are owned by us or by our licensors.</p>
          <p>In particular, we own the trademark “GoGalaxy.” © [Aug 23rd]-2023 “GoGalaxyTravels” is a registered trademark. All rights reserved. You do not have a right, license or permission to use any of them.</p>
        </div>
        <div>
          <h3><strong>PROHIBITED ACTIVITY</strong></h3>
          <p>As a visitor to or user of our Website, you must use it in a responsible and co-operative manner. The content and information on our Website (including but not limited to, price and availability of travel services) as well as the infrastructure used to provide such content and information, is proprietary to us, our providers, or our Travel Service Providers. While you make copies of your travel itinerary (and related documents) for travel or service reservations booking through our Website, you agree not to otherwise modify, copy, distribute, transmit display, perform, reproduce, publish, license, creative derivative works from, transfer, or sell or re-sell any information, software, products, or services obtained from or through our Website. Additionally, you agree not to:</p>
          <ul>
            <li>Make any fraudulent, speculative or false inquiries, bookings, or reservations, or make any reservations in anticipation of demand;</li>
            <li>Use any form of robot, spider, scraper or other automated means, or any comparable manual process, for the purpose of accessing, monitoring or copying any of the content or information on our Website without our prior written consent;</li>
            <li>Violate the restrictions in any robot exclusion headers on our Website or bypass or circumvent other measure employed to prevent or limit access to our Website;</li>
            <li>Place or enter false, misleading or incorrect information on our Website;</li>
            <li>Make any form of booking, reservation or request through our Website without fully intending to use that booking, reservation or request for legitimate travel purposes;</li>
            <li>Use another person’s name, user ID or password to make bookings, reservations or inquiries on our Website without that person’s prior permission;</li>
            <li>Use our Website while impersonating or acting as another person;</li>
            <li>Post on or transmit through our Website any unlawful, threatening, defamatory, libelous, obscene, indecent, inflammatory or pornographic material or images, or any other material that could give rise to or result in civil or criminal proceedings.</li>
            <li>Access or use our Website in any manner that, in our opinion, could impair, impede or otherwise negatively affect the proper functioning and performance of our Website and its systems, or that could negatively impact other visitors to or users of our Website</li>
            <li>Tamper with or hinder the operation of our Website or make unauthorized modifications to our Website</li>
            <li>Delete data from our Website without our permission;</li>
            <li>Knowingly transmit any virus, malware or other disabling feature or software to or through our Website;</li>
            <li>Breach the rights of any third party (including rights in intellectual property or contract as well as obligations of confidentiality or nondisclosure) or break any related laws in visiting or using our Website;</li>
            <li>Frame this Website as part of another website, or cache our Website for commercial gain or advantage.</li>
            <li>Disguise or mask the origin device and/or IP address information of the data being transmitted through our Website</li>
            <li>Knowingly permit or allow another person to do any of the above acts.</li>
          </ul>
          <p>We reserve the right to restrict or terminate your access to any or all of the features and components of our Website if we believe you have violated, or are violating, any of the above prohibitions. In the event of any such restriction or termination, you must immediately cease any</p>
          <p>Prohibited use of our Website. Attempting to access or use our Website in violation of any restrictions or terminations shall constitute an act of trespass. We will pursue legal action to the fullest extent possible against anyone whom we believe is in breach of the above prohibitions or is committing a trespass on our Website, and we reserve the right to do so.</p>
        </div>
        <div>
          <h3><strong>INDEMNIFICATION</strong></h3>
          <p>You agree to indemnify and defend GoGalaxyTravels.com and/or its Travel Service Providers and any of their officers, directors, employees and agents from and against any and all losses, damages, claims, causes of action, demands, recoveries, fines, penalties, or other costs and expenses of any kind or nature including, but not limited to reasonable legal and accounting fees brought by a third parties as a result of:</p>
          <ul>
            <li>Your breach of these Terms; or</li>
            <li>Your violation of any law or the rights of a third party; or</li>
            <li>Your use of our Website.</li>
          </ul>
        </div>
        <div>
          <h3><strong>RELEASE</strong></h3>
          <p>If you have a dispute with a Travel Service Provider, including but not limited to airlines, hotels, cruise lines, railroads, car rental agencies, tour operators and consolidators to perform the services offered by such suppliers, you release us (and our officers, directors, agents, subsidiaries, joint ventures, and employees) from claims, demands and damages (actual and consequential) of any kind and nature, known and unknown, arising out of or in any way connected with such disputes.</p>
        </div>
        <div>
          <h3><strong>YOUR PRIVACY</strong></h3>
          <p>GoGalaxyTravels.com believes in protecting your privacy. Please click here for our current Privacy Policy which also governs your use of our Website. It contains information about how GoGalaxyTravels.com collects, uses, and discloses information through the Services.</p>
        </div>
        <div>
          <h3><strong>DISCLAIMERS</strong></h3>
          <p>This Website and all its content are provided for your use on an “as is” and “where available” basis and at no charge. We make no warranties or representations, express or implied, to you or another person or entity as to the accuracy, results, timeliness, completeness, merchantability, fitness for any particular purpose with respect to our Website or any related materials, products, services, or information. Moreover, we do not warrant or represent that the content of our Website is accurate, current or complete, or that it does not infringe on the rights of others.</p>
          <p>We disclaim all implied warranties and representations to the maximum extent permitted by law, including, without limitation, implied warranties that the products and services offered, sold and provided through our Website will be of merchantable quality, are fit for any purpose or comply with the descriptions and samples displayed on our Website.</p>
          <p>The air carriers and other Travel Service Providers providing travel or other services on our Website are independent contractors and not agents or employees of GoGalaxyTravels.com. GoGalaxyTravels.com is not liable for the acts, errors, omissions, representations, warranties, breaches or negligence of any such Travel Service Provider or for any personal injuries, death, property damage, or other damages or expenses resulting therefrom. GoGalaxyTravels.com has no liability and will make no refund in the event of any delay, cancellation, overbooking, strike, force majeure or other causes beyond their direct control, and has no responsibility for any additional expenses, omissions, delays, re-routing or acts of any government or authority.</p>
          <p>Under no circumstances, including, but not limited to, negligence, shall we (including any of our officers, employees, agents, shareholders or other representatives), and/or our Travel Service Providers or distributors, be liable for any damages to, or viruses that may infect, your computer equipment or other property on account of your access to, use of, or browsing on the Site, or your downloading of any materials, data, text, images, video, audio, or other information from the Site.</p>
          <p>In no event shall we, and/or our Travel Service Providers or distributors, be liable for any injury, loss, claim, damage, or any special, punitive, indirect, incidental, or consequential damages of any kind (including, but not limited to, lost profits or lost savings), whether based in contract, tort, strict liability, or otherwise, that arise out of or are in any way connected with the use, or the inability to use, the Site or the services or materials on the Site, even if advised of the possibility of such damages.</p>
          <p>In no event shall our aggregate liability, or that of our providers or distributors, exceed the total charges set forth in the itinerary giving rise to any such liability. Any claim or cause of action arising from, or relating to, your access and use of, or purchase of products and/or services from, the Site must be brought within one (1) year from the date on which such claim or action arose or accrued or purchase was completed. Applicable law may not allow the limitation or exclusion of liability of incidental or consequential damages, so the above limitation or exclusion may not apply to you. Your use of the Site shall be at your own risk.</p>
          <p>We are acting as an intermediary or as an agent for providers of travel-related products and/or services by promoting, selling or accepting reservations or bookings for such products and/or services (such as air and ground transportation, hotel accommodations, meals, tours, cruises, travel insurance, etc.).Neither will we nor will any of our officers, employees, agents, shareholders or other representatives be liable in damages or otherwise to the maximum extent permitted by law in connection with your use of or inability to use or access our Website or your purchase and use of any products and services offered, promoted or sold on or through our Website.</p>
          <p>This limitation of liability applies to all damages of any kind, including compensatory, direct, indirect, special or consequential damages; loss of data, income or profit; loss of or damage to property; personal injury; and claims of third parties.</p>
          <p>If any warranties implied by law cannot be excluded, then our liability for breach of such warranties is limited, at our option, to:</p>
          <ul>
            <li>In the case of products: the replacement of the products or the supply of equivalent products; or the payment of the cost of replacing the products or acquiring equivalent products;</li>
            <li>In the case of services: the supply of the services again; or the payment of the cost of having the services supplied again.</li>
          </ul>
        </div>
        <div>
          <h3><strong>COMMUNICATIONS THROUGH WEBSITE</strong></h3>
          <p>You can communicate with us through our Website. Our Website also lists other ways you can communicate with us. Please be aware that by submitting content to our Website by electronic mail, postings on this Website or otherwise, including any hotel reviews, photos, videos, questions, comments, suggestions, ideas or the like contained in any submissions (collectively, “Submissions”), you grant GoGalaxyTravels.com, a non-exclusive, royalty-free, perpetual, transferable, irrevocable and fully sub-licensable right to (a) use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and publicly display and perform such Submissions throughout the world in any media, now known or hereafter devised; and (b) use the name that you submit in connection with such Submission. You acknowledge that GoGalaxyTravels.com may choose to provide attribution of your comments or reviews at our discretion. You further grant GoGalaxyTravels.com the right to pursue at law any person or entity that violates your or GoGalaxyTravels.com’s rights in the Submissions by a breach of these Terms.</p>
          <p>You acknowledge and agree that Submissions are non-confidential and non-proprietary.</p>
          <p>You expressly waive any and all ‘moral rights’ (including rights of attribution or integrity) that may subsist in your Submissions and agree that you have no objection to the publication, use, modification, deletion or exploitation of your Submissions by us. We take no responsibility and assume no liability for any Submissions posted or submitted by you. We have no obligation to post your comments; we reserve the right in our absolute discretion to determine which comments are published on the Website. If you do not agree to these Terms, please do not provide us with any Submissions.</p>
          <p>You are fully responsible for the content of your Submissions, (specifically including, but not limited to, reviews posted to this Website). You are prohibited from posting or transmitting to or from this Website: (i) any unlawful, threatening, libelous, defamatory, obscene, pornographic, or other material or content that would violate rights of publicity and/or privacy or that would violate any law; (ii) any commercial material or content (including, but not limited to, solicitation of funds, advertising, or marketing of any goods or services); and (iii) any material or content that infringes, misappropriates or violates any copyright, trademark, patent right or other proprietary right of any third party. You shall be solely liable for any damages resulting from any violation of the foregoing restrictions, or any other harm resulting from your posting of content to this Website. You acknowledge that GoGalaxyTravels.com may exercise its rights (e.g. use, publish, delete) to any content you submit without notice to you. If you submit more than one review for the same hotel, only your most recent submission is eligible for use.</p>
          <p>GoGalaxyTravels.com’s policies with respect to claims by third parties that the content of the Website, including the content of any Submissions, infringes the copyrights owned by said third party can be found in the Claims of Copyright Infringement section below.</p>
        </div>
        <div>
          <h3><strong>NOTICE OF INFRINGING MATERIAL</strong></h3>
          <p>If you believe that materials hosted by us infringe your copyright, please submit (or have your agent submit) to us notice under the Digital Millennium Copyright Act (DMCA) including all of the information requested below. If you fail to provide all of the requested information, we will not process your notice. You may wish to seek legal counsel prior to submitting a copyright infringement notice. You could be held liable for any alleged false claims of copyright infringement.</p>
          <ul>
            <li>A physical signature of the person authorized to act on behalf of the owner of the copyrighted work;</li>
            <li>A description of the copyrighted work that you claim has been infringed upon;</li>
            <li>A description of where the material that you claim is infringing is located on the Site;</li>
            <li>Your address, telephone number, and e-mail address;</li>
            <li>A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
            <li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf. Our DMCA Copyright Agent for notice of claims of copyright infringement on the site can be reached as follows: contact@GoGalaxyTravels.com</li>
          </ul>
          <p>We reserve the right in appropriate circumstances to remove content on the Site alleged to be infringing without prior notice, and/or to terminate the accounts of users who infringe any intellectual property rights of others.</p>
        </div>
        <div>
          <h3><strong>LINKING</strong></h3>
          <p>We may link our Website to other websites on the Internet. We do this strictly for your convenience and reference as you explore different travel options online. However, the inclusion of any such links does not indicate that we endorse the website or the business to which we have linked. Further, we have not verified the content of any website to which we have linked, and we bear no responsibility whatsoever for the content of any linked website. It is up to you to take precautions to ensure that whatever links you select or software you download (whether from our Website or other websites) is free of such items as viruses, worms, trojan horses, defects, and other items of a destructive nature. Should you incur any loss or damage from visiting or doing business with any linked website or business, we are not liable for that loss or damage.</p>
        </div>
        <div>
          <h3><strong>TERMINATION</strong></h3>
          <p>We reserve the right to immediately terminate this Agreement as well as any other agreement between you and us if you breach any of these Terms and Conditions. We further reserve the right, in our sole discretion, and without liability, to terminate your access to all or part of the Site, with or without notice, for any reason or no reason.
          </p>
        </div>
        <div>
          <h3><strong>OUR RELATIONSHIP</strong></h3>
          <p>No agency, partnership, joint venture, employer-employee or franchisor-franchisee relationship exists between you and us, nor is such a relationship created between you and us by these Terms and Conditions or by our Agreement with you.</p>
        </div>
        <div>
          <h3><strong>NOTICES</strong></h3>
          <p>You must provide any notices to us by email to contact@GoGalaxyTravels.com unless we specify otherwise in this Agreement and except as required by law.</p>
          <p>We will provide any notices to you by email to the email address you give us. Emailed notice will be deemed to have been received by the receiving party 24 hours after the email was sent unless the sending party receives electronic notification that the receiving email address is invalid or otherwise that the email was not delivered to or received by the receiving party.</p>
        </div>
        <div>
          <h3><strong>MEDIA RELATIONS</strong></h3>
          <p>For all media inquiries, please send an email to the following address: contact@GoGalaxyTravels.com</p>
        </div>
        <div>
          <h3><strong>GENERAL</strong></h3>
          <p>If any of these Terms and Conditions is found by a court or other legal authority to be invalid or unenforceable, the invalid or unenforceable provisions will be stricken. The remaining terms and conditions will remain in full force and effect.</p>
          <p>The headings used in these Terms and Conditions are for reference purposes only.</p>
          <p>If we take no action in response to a violation by you or others of one or more of these Terms and Conditions, that inaction shall not constitute a waiver of the violated terms and conditions and shall not impair our right to take action in response to subsequent or similar violations. In this Agreement, the term “website” includes any email bulletins or other content that we provide to you through our Website or otherwise initiated from our Website.</p>
        </div>
      </div>
    </div>
  );
};

export default TncPage;
